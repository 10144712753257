import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

// Layout
import SecondaryLanding from "../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import BranchSearch from "../branch-search/branch-search";
import VimeoVideoFrame from "../custom-widgets/vimeo-video-frame";
import Icon from "../custom-widgets/icon";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

export const query = graphql`
  query stateBestBanksHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
      StateGeoLocation {
        Lat
        Lng
        Zoom
      }
    }
  }
`;

const StateBestBanksTemplate = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };

  const SearchData = {
    StateName: StateName,
    StateCode: stateData.StateCode,
    GMData: {
      center: {
        lat: stateData.StateGeoLocation.Lat,
        lng: stateData.StateGeoLocation.Lng
      },
      zoom: stateData.StateGeoLocation.Zoom,
      title: StateName,
      markers: []
    }
  };

  const metaData = {
    ID: {
      metaTitle: "Awarded Best Bank in Idaho",
      metaDescription:
        "Deciding on the right bank depends on your personal needs and preferences. Discover WaFd Bank, awarded the best big bank in Idaho by Newsweek."
    },
    NM: {
      metaTitle: "Awarded Best Bank in New Mexico",
      metaDescription:
        "Deciding on the right bank depends on your personal needs and preferences. Discover WaFd Bank, awarded the best big bank in New Mexico by Newsweek."
    },
    NV: {
      metaTitle: "Awarded Best Bank in Nevada",
      metaDescription:
        "Deciding on the right bank depends on your personal needs and preferences. Discover WaFd Bank, voted Best Bank in Nevada by Forbes."
    },
    OR: {
      metaTitle: "Awarded Best Bank in Oregon",
      metaDescription:
        "Deciding on the right bank depends on your personal needs and preferences. Discover WaFd Bank, voted Best Bank in Oregon by Forbes."
    },
    WA: {
      metaTitle: "Awarded Best Bank in Washington State",
      metaDescription:
        "Deciding on the right bank depends on your personal needs and preferences. Discover WaFd Bank, awarded the best big bank in Washington State by Newsweek."
    }
  };

  const SEOData = {
    title: metaData[StateCode].metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaData[StateCode].metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaData[StateCode].metaDescription
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/about-us/best-bank/" +
          (stateData.Slug === "washington" ? "washington-state" : stateData.Slug)
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-best-bank-" + stateData.Slug + "-071423.jpg"
      }
    ]
  };

  const heroAltText = {
    NM: "Sante Fe, New Mexico, with the Sangre de Cristo mountains in the background.",
    NV: "Las Vegas, Nevada, suburbs with Frenchman Mountain in the background.",
    ID: "Boise, Idaho, with the Sawtooth Mountains in the background.",
    OR: "Downtown Portland, Oregon, with Mount Hood in the background.",
    WA: "Aerial view of downtown Seattle, the waterfront, and Elliott Bay."
  };

  const heroChevronData = {
    id: "state-best-banks-hero",
    ...getHeroImgVariables(data),
    altText: heroAltText[StateCode],
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: `${StateName}'s Best Bank`
          }
        },
        {
          id: 2,
          button: {
            id: "state-best-banks-hero-cta-1",
            class: "btn-white",
            text: "Open an Account",
            url: "/open-bank-account-online",
            containerClass: "mb-4"
          }
        },
        {
          id: 2,
          button: {
            id: "state-best-banks-hero-cta-2",
            class: "btn-light",
            text: "Apply for a Home Loan",
            url: "/personal-banking/home-loans"
          }
        }
      ]
    }
  };

  const cityPageLinks = {
    WA: {
      cityName: "Seattle",
      cityUrl: "/locations/washington/seattle",
      id: "wa-seattle-city-page"
    },
    ID: {
      cityName: "Boise",
      cityUrl: "/locations/idaho/boise",
      id: "id-boise-city-page"
    },
    NM: {
      cityName: "Santa Fe",
      cityUrl: "/locations/new-mexico/santa-fe",
      id: "nm-santa-fe-city-page"
    },
    NV: {
      cityName: "Las Vegas",
      cityUrl: "/locations/nevada/las-vegas",
      id: "nv-las-vegas-city-page"
    },
    OR: {
      cityName: "Portland",
      cityUrl: "/locations/oregon/portland",
      id: "or-portland-city-page"
    }
  };

  const videoData = {
    vimeoId: "683042004",
    videoTitle: "Forbes 2022 America's Best Midsize Employers"
  };

  const BEST_BANK_STATES = StateCode === "WA" || StateCode === "ID" || StateCode === "NM";
  const BEST_IN_STATE_BANK_STATES = StateCode === "WA" || StateCode === "NV" || StateCode === "NM";

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />
      <section className="container">
        <h1>WaFd Bank Is Proud to Be Awarded Best Bank in {StateName}!</h1>
        <div className="row mt-5">
          <div className="col-md-6">
            <h3 className="text-success font-weight-bold">
              Working together isn't new for us. Here's how we earned this honor.
            </h3>

            {BEST_BANK_STATES && (
              <p>
                Newsweek Best Bank winners are assessed on more than 30 separate factors, covering the overall health of
                the bank, customer service performance and features, digital and branch presence, account and loan
                options, interest rate offerings and fees. Among the specific data collected: the average service
                charges collected on deposit accounts during the first quarter of 2023; average interest paid on deposit
                accounts during the first quarter of 2023; the number of complaints submitted to the Consumer Financial
                Protection Bureau and how quickly the bank responded, the kinds of loan and account products offered;
                mobile app scores; the kinds of customer service features offered; overall health of the bank as
                determined by DepositAccounts.com; and, depending on the Best Bank accolade, branch presence.
              </p>
            )}

            {BEST_IN_STATE_BANK_STATES && (
              <p>
                Forbes partnered with market research firm Statista to survey approximately 31,000 U.S. residents, all
                of whom were asked to name and evaluate the institutions where they have a checking or savings account.
                Criteria included customer service, the quality of financial advice staff were able to offer, whether
                fees are transparent and reasonable, and the overall level of trust they inspired, among others. WaFd
                Bank is proud to have received this award in {StateName}!
              </p>
            )}
            <p>
              From digital options, touchless transactions and increased non-profit contributions to help our{" "}
              {StateName} neighbors, we're still here for YOU. Our mission is to strengthen communities and the
              financial well-being of our clients by delivering secure, reliable, common sense banking solutions, and
              we're committed to building relationships with clients, shareholders, and the community at large.
              Together, we create opportunities that strengthen neighborhoods and the lives of those we serve.
            </p>
            <h5 className="text-success">When other lenders are pulling back, we're leaning in.</h5>
            <h2>WaFd Bank Locations in {StateName}</h2>
            <p>
              Come see us at your local {StateName} neighborhood bank, with bankers that care about knowing your name
              and helping you find financial success. Whether you're a business in the{" "}
              <Link id={cityPageLinks[StateCode].id} to={cityPageLinks[StateCode].cityUrl}>
                {cityPageLinks[StateCode].cityName}
              </Link>{" "}
              area or an individual looking for banking services to help strengthen your financial future, WaFd Bank can
              help. From{" "}
              <Link id="state-free-checking-link" to={`/personal-banking/free-checking-account/${stateData.Slug}`}>
                {StateName} Free Checking
              </Link>{" "}
              accounts to{" "}
              <Link
                id="state-construction-loans-link"
                to={`/personal-banking/home-loans/construction-loans/${stateData.Slug}`}
              >
                Construction Loans
              </Link>{" "}
              to{" "}
              <Link id="state-heloc-loans-link" to={`/personal-banking/home-loans/heloc/${stateData.Slug}`}>
                Home Equity Lines of Credit
              </Link>{" "}
              to{" "}
              <Link id="small-business-link" to="/business-banking/small-business">
                small business banking
              </Link>{" "}
              and{" "}
              <Link id="commercial-lending-link" to="/commercial-banking/commercial-lending">
                commercial lending services
              </Link>
              , WaFd Bank is right here in {StateName} for all of your banking needs.
            </p>
          </div>
          <div className="col-md-6">
            <div className="row mb-3">
              {BEST_BANK_STATES && (
                <div className="col-6">
                  <StaticImage
                    src="../../images/newsweek-3yr-lockup-600.png"
                    alt="America's Best Bank 2021, 2022 and 2023 - Newsweek"
                    placeholder="blurred"
                    loading="eager"
                    quality="100"
                    height={225}
                  />
                </div>
              )}

              {BEST_IN_STATE_BANK_STATES && (
                <div className="col-6">
                  <StaticImage
                    src="../../images/forbes-logo-2024-with-shadow.png"
                    alt="WaFd Bank voted Best Bank by Newsweek"
                    placeholder="blurred"
                    quality="100"
                    height={225}
                  />
                </div>
              )}
            </div>

            {BEST_BANK_STATES && (
              <>
                <h3>Awarded America's Best Big Bank by Newsweek</h3>
                <ul className="mb-0">
                  <li>
                    <Link id="washington-best-bank-link" to="/about-us/best-bank/washington-state">
                      Best Big Bank in Washington
                    </Link>
                  </li>
                  <li>
                    <Link id="idaho-best-bank-link" to="/about-us/best-bank/idaho">
                      Best Big Bank in Idaho
                    </Link>
                  </li>
                  <li>
                    <Link id="new-mexico-best-bank-link" to="/about-us/best-bank/new-mexico">
                      Best Big Bank in New Mexico
                    </Link>
                  </li>
                </ul>
                <p>
                  <a
                    id="newsweek-site-cta"
                    className="text-decoration-none"
                    href="https://www.newsweek.com/rankings/americas-best-banks-2023/best-big-bank-2023"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Newsweek Best Big Banks 2023
                    <Icon name="arrow-right" class="ml-1" />
                  </a>
                </p>
              </>
            )}

            {BEST_IN_STATE_BANK_STATES && (
              <>
                <h3>Awarded America's Best-in-State Bank by Forbes</h3>
                <ul className="mb-0">
                  <li>
                    <Link id="washington-best-in-state-bank-link" to="/about-us/best-bank/washington-state">
                      Best in-State in Washington
                    </Link>
                  </li>
                  <li>
                    <Link id="nevada-best-in-state-bank-link" to="/about-us/best-bank/nevada">
                      Best in-State in Nevada
                    </Link>
                  </li>
                  <li>
                    <Link id="new-mexico-best-in-state-bank-link" to="/about-us/best-bank/new-mexico">
                      Best in-State in New Mexico
                    </Link>
                  </li>
                </ul>
                <p>
                  <a
                    id="best-in-state-banks-link-forbes"
                    className="text-decoration-none"
                    href="https://www.forbes.com/lists/best-in-state-banks/?sh=7992502e709e"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Forbes Best-in-State Banks 2024
                    <Icon name="arrow-right" class="ml-1" />
                  </a>
                </p>
              </>
            )}
          </div>
        </div>
      </section>
      <BranchSearch {...SearchData} />
      <section className="container">
        <div className="row">
          <div className="col-md-3">
            <h3 className="text-success">Not just a great place to bank, a great place to work!</h3>
            <p>WaFd Bank named one of America's Best Employers</p>
          </div>
          <div className="col-md-6">
            <VimeoVideoFrame {...videoData} />
          </div>
        </div>
      </section>
      <section className="bg-green-60">
        <div className="container">
          <div className="d-sm-flex">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <StaticImage
                src="../../images/forbes-badge.jpg"
                alt="America's Best Midsize Employers"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-sm-6 col-md-8 col-lg-7">
              <h3 className="text-white">Forbes recognized WaFd Bank as one of America's Best Employers</h3>
              <Link id="wafd-careers-cta" to="/about-us/banking-careers" className="text-white text-decoration-none">
                Explore WaFd Careers
                <Icon name="arrow-right" lib="fal" class="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

StateBestBanksTemplate.propTypes = {
  branch: PropTypes.object
};

export default StateBestBanksTemplate;